.boxMapa {
  margin-top: 20px;
  position: relative;
  width: 100%;
  height: 350px;
  overflow: hidden;
}

.mapContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: inherit;
  background-color: lightgrey;
}

.marker {
  background-image: url('../../../components/v2-mobili/img/home.png');
  background-size: cover;
  width: 50px;
  height: 50px;
  /* border-radius: 50%; */
  cursor: pointer;
}
.mapboxgl-popup {
  max-width: 200px;
}
.mapboxgl-popup-content {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  border-radius: 12px !important;
}

.mapboxgl-popup-content img {
  max-width: 200px;
  height: 60px;
  object-fit: cover;
}

.btnLocation {
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 2;
}

.mapbox-logo {
  display: none !important;
}

.leaflet-bottom,
.leaflet-top {
  z-index: 0 !important;
  position: absolute;
  pointer-events: none;
}
