.main {
  max-width: 1000px;
  margin: 0 auto;
  /* padding: 1.3em 1.5em; */
}

.galleries {
  columns: 4 90px;
  column-gap: 1.2em;
  height: auto;
  overflow: hidden;
}
.imagesBox {
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 1em;
  width: 100%;
  height: auto;
  /* background-color: pink; */
  /* height: 100px; */
}
.imagesBox button {
  border: none;
  background-color: transparent;
  /* width: 100%; */
  /* height: 100%; */
  /* overflow: hidden; */
  /* border-radius: 10px; */
  /* margin-bottom: 1em; */
}
.imagesBox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* display: block; */
  cursor: zoom-in;
  transition: transform 0.25s ease;
  transform: scale(1.2);
  border-radius: 10px;
}
.imagesBox:hover img {
  transform: rotate(2deg) scale(1.3);
}
