.card-media{
  background-color: #888888 !important;

  .div-text{
    top: 45%;
  }

  & img{
    display: inline-block;
    -webkit-transition: all .75s ease-in-out;
    -moz-transition: all .75s ease-in-out;
    -ms-transition:  all .75s ease-in-out;
    -o-transition:  all .75s ease-in-out;
    transition:  all .75s ease-in-out;

    &:hover{
      display: inline-block;
      -webkit-transition: all .75s ease-in-out;
      -moz-transition: all .75s ease-in-out;
      -ms-transition:  all .75s ease-in-out;
      -o-transition:  all .75s ease-in-out;
      transition:  all .75s ease-in-out;
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -ms-transform: scale(1.1);
      -o-transform: scale(1.1);
      transform: scale(1.1);
    }
  }
}

.object-fit-cover{
  object-fit: cover;
  object-position: center;
}

.height-250 {
  height: 250px !important;
}

.bottom-32 {
  bottom: 32% !important;
}

.border-radius-7{
  -webkit-border-radius: 7px !important;
  -moz-border-radius: 7px !important;
  -ms-border-radius: 7px !important;
  -o-border-radius: 7px !important;
  border-radius: 7px !important;
}

.bg-green-light{
  background-color: #F6FDE5 !important;
}

.height-32 {
  height: 32px !important;
}

.height-40 {
  height: 40px !important;
}

.width-28{
  width: 28px !important;
}

.z-100{
  z-index: 100 !important;
}

.z-1000{
  z-index: 1000 !important;
}

.height-130 {
  height: 130px !important;
}

.color-dark{
  color: #333333;
}


.height-60 {
  height: 60px !important;
}

.width-40 {
  width: 40px !important;
}

.height-40 {
  height: 40px !important;
}

.width-25 {
  width: 25px !important;
}

.bottom-35 {
  bottom: 35% !important;
}

.bottom-95 {
  bottom: 95% !important;
}

.height-243 {
  height: 243px !important;
}

.height-250 {
  height: 250px !important;
}

.height-300 {
  height: 300px;
}

.box-shadow-card{
  -webkit-box-shadow: 0 0 6px #00000029 !important;
  -moz-box-shadow: 0 0 6px #00000029 !important;
  -ms-box-shadow: 0 0 6px #00000029 !important;
  -o-box-shadow: 0 0 6px #00000029 !important;
  box-shadow: 0 0 6px #00000029 !important;
}

.height-20 {
  height: 20px !important;
}

.width-20 {
  width: 20px !important;
}

.btn-group {
  position: relative;
  display: block !important;
  vertical-align: middle;
  width: 100% !important;
  .btn-outline-white{
    background-color: #fff;
    border: 1px solid #85BF02;
    box-shadow: none !important;
    color: #85BF02;
    outline: 0 !important;
    float: left !important;
    width: 25%;

    &:hover,
    &.active{
      background-color: #85BF02;
      border: 1px solid #85BF02;
      color: #fff;
      box-shadow: none !important;
      outline: 0 !important;
    }
  }
}

@media (max-width: 650px) {
  .btn-group {
    .btn-outline-white{
      width: 100% !important;
    }
  }
}

.status-pointer {
  cursor: pointer;
  border-radius: 50%;
  height: 15px;
  width: 15px;
}

.scrolling {
  -webkit-transition-property: top, bottom, width;
  -o-transition-property: top, bottom, width;
  transition-property: top, bottom, width;
  -webkit-transition-duration: .2s, .2s, .35s;
  -o-transition-duration: .2s, .2s, .35s;
  transition-duration: .2s, .2s, .35s;
  -webkit-transition-timing-function: linear, linear, ease;
  -o-transition-timing-function: linear, linear, ease;
  transition-timing-function: linear, linear, ease;
  -webkit-overflow-scrolling: touch;
  position: relative;
  overflow-x: hidden;
  height: calc(95vh - 200px);
  z-index: 5;
}

.height-45 {
  height: 45px !important;
}

.btn-upload {
  border-radius: 8px;
  border: 1px solid #000;
  background: rgba(0, 0, 0, 0.10);
  padding: 5px 10px;
  font-weight: 700;
  width: max-content;
  align-items: center;
  display: inline-flex;
  gap: .5rem;
}

.btn-upload:hover{
  background: rgba(0, 0, 0, 0.20); 
}

.publish-btn {
  background-color: rgba(66, 153, 247, 0.43);
  border: 1px solid rgba(66, 153, 247, 1);
  transition: all ease-in-out 150ms;
}
.publish-btn:hover {
  background-color: rgba(66, 153, 247, 0.63);
  color: white;
}