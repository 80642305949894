.gridContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  /* margin-top: 10; */
}

.gridContainer button {
  border: none;
  width: 100%;
  height: 125px;
  padding: 0;
  margin: 0;
  position: relative;
}
.gridContainer img {
  width: 100%;
  height: 125px;
  border-radius: 5px;
  object-fit: cover;
  background-color: lightgray;
}
.gridContainer button::after {
  content: '';
  position: absolute;
  width: 40px;
  height: 40px;
  top: 10px;
  right: 10px;
  background-image: url('../img/icon360.svg');
}
