.cardForm {
  margin-top: 8px;
  background-color: #ffffff;
  padding: 1.25em 2.1875em;
  border-bottom: 2px solid #eef0f3;
}
.dFlexBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.25rem;
  margin-top: 25px;
}

.box {
  background: #f8fafc;
  border-radius: 10px;
  padding: 0.9375rem;
  margin: 25px 0;
}

.boxTitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #8c99a3;
}

.box p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #273137;
  margin: 0;
}

.box ul {
  list-style-image: url(../img/facebook.svg);
}
.box ul li {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 125%;
  color: #273137;
}

@media (max-width: 576px) {
  .dFlexBetween {
    flex-direction: column;
  }
  .cardForm {
    padding: 1.25em;
  }
}
