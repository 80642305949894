.gridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0 40px;
  grid-template-areas:
    'left right'
    'footerMain right';
}
.left {
  grid-area: left;
}
.right {
  grid-area: right;
  background-color: #ffffff;
}

.footerMain {
  grid-area: footerMain;
}

.cardCustom {
  background-color: #ffffff;
  padding: 1.25em 2.1875em;
  border-bottom: 2px solid #eef0f3;
  box-shadow: 0px 0px 1px rgba(15, 23, 42, 0.03),
    0px 4px 6px -1px rgba(15, 23, 42, 0.05),
    0px 0px 5px 5px rgba(15, 23, 42, 0.03);
}

.dFlex {
  display: flex;
  justify-content: space-between;
}

.dFlex span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  color: #8c99a3;
}
.dFlex_left span:nth-of-type(2),
.dFlex_right span:nth-of-type(2) {
  color: #273137;
}

@media (max-width: 768px) {
  .gridContainer {
    grid-template-columns: 1fr;
    grid-template-areas:
      'left '
      'right'
      'footerMain';
  }

  .right {
    margin-top: 1rem;
  }
}
