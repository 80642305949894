.tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.tabs__item {
  padding: 6px 20px;
  border: none;
  display: block;
  background-color: #f8fafc;
  font-size: 1rem;
  font-weight: 400;
  line-height: 150%;
  border-radius: 2px;
  min-width: 150px;
}
.tabsHeader__item {
  width: 100%;
}

.tabs__item.active {
  background-color: #273137;
  color: #ffffff;
}

@media (max-width: 600px) {
  .tabs {
    flex-direction: column;
  }
}
