
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: #fffffff2;
}

::-webkit-scrollbar-thumb:hover {
  background: #363636;
}

.custom-scroll {
  scrollbar-width: thin;
  scrollbar-color: #000 #fffffff2;
}
