.cardCustom {
  background-color: #ffffff;
  padding: 1.25em 2.1875em;
  border-bottom: 2px solid #eef0f3;
  box-shadow: 0px 0px 1px rgba(15, 23, 42, 0.03),
    0px 4px 6px -1px rgba(15, 23, 42, 0.05),
    0px 0px 5px 5px rgba(15, 23, 42, 0.03);
  position: sticky;
  top: 56px;
  z-index: 4;
}

.wrapperBtn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  margin-bottom: 8px;
}

.btnLeftArrow {
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #69757c;
  padding: 0 24px;
  height: 30px;
  cursor: pointer;
}

.btnLeftArrow::before {
  content: '';
  margin-right: 13px;
  display: block;
  height: 14px;
  width: 14px;
  /* background-image: url(../../img/left_arrowr.svg); */
  background-image: url(../../../../../../components/v2-mobili/img/left_arrowr.svg);
  background-repeat: no-repeat;
  float: left;
  margin-top: 5px;
}

.propertyDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}
.propertyDetails span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  color: #69757c;
}
.propertyDetails img {
  margin-right: 4px;
}
