.cardForm {
  margin-top: 8px;
  background-color: #ffffff;
  padding: 1.25em 2.1875em;
  border-bottom: 2px solid #eef0f3;
}
.dFlexBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.25rem;
  margin-top: 25px;
}
.dFlexBetweenTwo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.25rem;
  width: 100%;
}

.box {
  background: #f8fafc;
  border-radius: 10px;
  padding: 0.9375rem;
  margin: 25px 0;
}

.boxTitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #8c99a3;
}

.box p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #273137;
  margin: 0;
}

.boxFeatures {
  display: grid;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}

.boxFeatures ul {
  /* list-style-image: url(../../../../pages/owners/views/inventoryPage/img/icon_Check.svg); */
  list-style-image: url(../../../../components/v2-mobili/img/icon_Check.svg);
  padding: 0 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  margin: 0;
  max-width: 165px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.boxFeatures ul li {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 125%;
  color: #273137;
}

.boxFeatures {
  display: grid;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}

@media (max-width: 576px) {
  .dFlexBetween {
    flex-direction: column;
  }
  .cardForm {
    padding: 1.25em;
  }
}
