@charset "UTF-8";
@import '../../../../assets/scss/colors';
@import '../../../../assets/scss/fonts';

// .menu-responsive {
//     -webkit-transition: all 1s ease;
//     -moz-transition: all 1s ease;
//     -ms-transition: all 1s ease;
//     -o-transition: all 1s ease;
//     transition: all 1s ease;
//     -webkit-transform: translateX(230px);
//     -moz-transform: translateX(230px);
//     -ms-transform: translateX(230px);
//     -o-transform: translateX(230px);
//     transform: translateX(230px);
// }

// .menu-responsive.menu-responsive-open {
//     -webkit-transition: all 1s ease;
//     -moz-transition: all 1s ease;
//     -ms-transition: all 1s ease;
//     -o-transition: all 1s ease;
//     transition: all 1s ease;
//     -webkit-transform: translateX(0px);
//     -moz-transform: translateX(0px);
//     -ms-transform: translateX(0px);
//     -o-transform: translateX(0px);
//     transform: translateX(0px);
// }

.wrapper {
    display: flex;
    position: relative;

    .menu-responsive {
        transform: translateX(230px);
        -webkit-transition: all .5s ease;
        -moz-transition: all .5s ease;
        -ms-transition: all .5s ease;
        -o-transition: all .5s ease;
        transition: all .5s ease;
        padding: 0;

    }

    .menu-responsive.menu-responsive-open {
        transform: translateX(0px);
        -webkit-transition: all .5s ease;
        -moz-transition: all .5s ease;
        -ms-transition: all .5s ease;
        -o-transition: all .5s ease;
        transition: all .5s ease;
    }
}