.footer {
  background: linear-gradient(
      0deg,
      rgba(166, 223, 255, 0.1),
      rgba(166, 223, 255, 0.1)
    ),
    #ffffff;
  padding: 2.1875rem;
  margin-top: 1.25rem;
}

.footer a {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  color: #004087;
}
.dFlexLogo {
  max-width: 176.67px;
}
.dFlexWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem 0;
}
.dFlexBetween {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
}

.dFlexStart {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1.875rem;
  margin-top: 40px;
  margin-bottom: 50px;
}
.dFlexStart p {
  margin: 0;
}

.logo {
  margin-bottom: 10px;
}
.logo img {
  max-width: 140px;
  display: block;
}

.dFlexAbout a {
  font-family: 'Roboto';
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 125%;
  color: #273137;
  display: block;
  margin-bottom: 15px;
}

.dFlexAbout a:hover {
  color: #1b65b9;
  text-decoration: underline;
}
.socialMedia {
  display: flex;
  flex-direction: row;
  gap: 1.875rem;
}
.copyright {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 150%;
  color: #004087;
}

@media (max-width: 576px) {
  .dFlexBetween {
    flex-direction: column;
  }
  .dFlexStart {
    flex-direction: column;
    align-items: flex-start;
  }
}
