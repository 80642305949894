.gridContainer {
  max-width: 1450px;
  margin: 0 auto;
  margin-top: 60px;
}

.box {
  display: grid;
  grid-template-columns: 1fr 550px;
  grid-template-areas: 'left right';
  gap: 40px;
}

.left {
  grid-area: left;
}

.right {
  grid-area: right;
  padding: 0 1rem;
}

.boxPrice {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1.4rem;
  width: 100%;
}

.boxAddress {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.boxFeatures {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.boxFeaIcon {
  display: flex;
  align-items: center;
  gap: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
}

.boxFeaturesList {
  margin-top: 20px;
  display: grid;
  align-items: center;

  gap: 0.75rem;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  background: #f8fafc;
  border-radius: 10px;
  padding: 20px;
  overflow-y: auto;
}

.boxFeaturesList ul {
  list-style-image: url(../../../../components/v2-mobili/img/icon_Check.svg);
  padding: 0 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  margin: 0;
  max-width: 165px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.boxFeaturesList ul li {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 125%;
  color: #273137;
}

@media (max-width: 1024px) {
  .box {
    grid-template-columns: 1fr;
    grid-template-areas: 'left' 'right';
  }

  .boxPrice {
    padding: 0 2.1875em;
    /* margin: 0; */
  }
}

@media (max-width: 600px) {
  .gridContainer {
    margin-top: 30px;
  }

  .boxAddress {
    flex-direction: column;
    gap: 20px;
  }
}
