.header {
  background-color: #273137;
  width: 100%;
  height: 55px;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 2.1875em;
  z-index: 5;
}
