.box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  object-fit: cover;
}

.infoUser {
  display: flex;
  flex-direction: column;
}

.infoUserTel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.infoUserTel span::before {
  content: '';
  display: inline;
  float: left;
  background-image: url(../../../../components/v2-mobili/img/iconTel.svg);
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.infoEmail {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5px;
}

.infoEmail span::before {
  content: '';
  display: inline;
  float: left;
  background-image: url(../../../../components/v2-mobili/img/iconMail.svg);
  background-repeat: no-repeat;
  width: 20px;
  height: 14px;
  margin-right: 6px;
  background-size: 19px;

  /* background-color: aqua; */
  /* padding-right: 5px; */
}

.box h1 {
  max-width: 540px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.infoUser h3 {
  max-width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.infoEmail span {
  max-width: 260;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 1024px) {
  .box {
    padding: 0 2.1875em;
    /* flex-direction: column; */
  }
}

@media (max-width: 768px) {
  .box {
    padding: 0 2.1875em;
  }

  .infoUser h3 {
    max-width: 233px;
  }
  .box h1 {
    max-width: 302px;
    font-size: 1.875em;
  }
  .box h4 {
    font-size: 1em;
  }
}

@media (max-width: 600px) {
  .box {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0.75rem;
  }
  .box div:nth-child(2) {
    padding-left: 0px;
    padding-right: 5px;
  }
}
