.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}
.wrapperVideo {
  position: relative;
  width: 100%;
  max-width: 350px;
}
.videoMain {
  background-color: lightgray;
  object-fit: cover;
  width: 100%;
  max-width: 350px;
  height: 125px;
  border-radius: 5px;
  cursor: pointer;
  display: block;
}

.wrapperVideo::after {
  content: '';
  position: absolute;
  width: 34px;
  height: 34px;
  top: 10px;
  right: 10px;
  background-image: url('../img/iconPlayVideo.svg');
}

@media (max-width: 600px) {
  .wrapperVideo {
    width: 100%;
    max-width: none;
  }

  .videoMain {
    width: 100%;
    max-width: none;
  }
}
