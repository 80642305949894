.footer {
  background: linear-gradient(
      0deg,
      rgba(166, 223, 255, 0.1),
      rgba(166, 223, 255, 0.1)
    ),
    #ffffff;
  padding: 2.1875rem;
  margin-top: 1.25rem;
}

.dFlexBetween {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
}

.dFlexStart {
  display: flex;
  justify-content: flex-start;
  gap: 1.5rem;
  margin: 1.25rem 0;
}

.logo {
  margin-bottom: 10px;
}
.logo img {
  max-width: 140px;
  display: block;
}

.dFlexAbout a {
  font-family: 'Roboto';
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 125%;
  color: #273137;
  display: block;
  margin-bottom: 15px;
}

.dFlexAbout a:hover {
  color: #1b65b9;
  text-decoration: underline;
}

.copyright {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 150%;
  color: #004087;
}

@media (max-width: 576px) {
  .dFlexBetween {
    flex-direction: column;
  }
}
