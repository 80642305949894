.gridContainerImg {
  height: 100%;
  position: relative;
}

.gridContainerImg img {
  max-width: 100%;
  width: 100%;
  display: block;
  object-fit: cover;
  object-position: center center;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.gridContainerImg button {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
}
