.propertyDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
  }
  .propertyDetails span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 150%;
    color: #69757c;
  }
  .propertyDetails img {
    margin-right: 4px;
  }