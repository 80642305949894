.main {
  max-width: 1000px;
  margin: 0 auto;
}

.galleries2 {
  columns: 3 90px;
  column-gap: 1.2em;
  height: auto;
  overflow: hidden;
}

.galleries {
  /* display: grid; */
  /* grid-template-columns: repeat(auto-fill,minmax(140px,1fr)); */
  columns: 4 90px;
  column-gap: 1.2em;
  /* height: auto;
  overflow: hidden; */
}
.imagesBox {
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 1em;
  width: 100%;
}
.imagesBox button {
  border: none;
  background-color: transparent;
}
.imagesBox img {
  background-color: lightgray;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: zoom-in;
  transition: transform 0.25s ease;
  transform: scale(1.2);
  border-radius: 10px;
}
.imagesBox:hover img {
  transform: rotate(2deg) scale(1.3);
}
.wrapperBoxGallery {
  position: relative;
}
.btnGoUp {
  border: none;
  background: #eef0f3;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  bottom: 20px;
  right: 50px;
  transition: all 300ms ease;
  opacity: 0;
  visibility: hidden;
  transform: scale(0);
}
.btnGoUp:hover {
  transform: scale(1.1);
}
.btnGoUp.active {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
