.popUp {
  z-index: 9999;
  background: rgba(48, 48, 48, 0.3);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: all 0.1s ease;
  overflow-y: auto;
  overflow-x: hidden;
}

.popUp .popUpWrapper {
  transform: scale(0.8);
  opacity: 0;
  transition: all 0.1s ease;
}

.popUp .popUpWrapper .modal {
  background-color: white;
  padding: 0.5em;
  text-align: center;
  position: relative;
  box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.2);
}

.popUp.show {
  visibility: visible;
  opacity: 1;
  transition: all 0.2s ease;
}

.popUpWrapper.show {
  transform: scale(1.7);
  opacity: 1;
  transition: all 0.6s ease;
}

.modalBody {
  position: relative;
  transition: all 0.6s ease;
}

.leftArrow {
  position: absolute;
  top: 50%;
  left: 0.5rem;
  background: none;
  border: none;
  font-size: 2em;
  color: #ffffff;
  cursor: pointer;
  z-index: 1;
  transform: translate(0, -50%);
}

.rightArrow {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  background: none;
  border: none;
  font-size: 2em;
  color: #ffffff;
  cursor: pointer;
  z-index: 1;
  transform: translate(0, -50%);
}

.btnClose {
  position: absolute;
  top: -2px;
  right: -27px;
  background: #ffffff;
  border: none;
  font-size: 12px;
  color: black;
  cursor: pointer;
  z-index: 1;
  border-radius: 50%;
  padding: 0.5em;
  max-width: 20px;
  min-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalBody img {
  max-width: 90vw;
  max-height: 53vh;
}
.modalBody video {
  max-width: 90vw;
  height: 50vh;
  /* padding-bottom: 56.25%; */
}
@media (max-width: 768px) {
  .popUpWrapper.show {
    transform: scale(1.1);
  }
  .modalBody img {
    max-width: 90vw;
    max-height: 80vh;
  }
  .modalBody video {
    /* max-width: 90vw; */
    height: 35vh;
    /* padding-bottom: 56.25%; */
  }
  .popUp .popUpWrapper {
    padding: 60px;
  }
  .btnClose {
    top: -27px;
    right: 0;
  }
}

.videosResponsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
  margin-bottom: 1em;
}
.videosResponsiveItem {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  border: 0;
}

.videosResponsive__16by9 {
  padding-bottom: 56.25%;
}

@media (max-width: 576px) {
  .popUpWrapper.show {
    transform: scale(1);
  }
  .modalBody img {
    height: auto;
  }
  .modalBody video {
    /* max-width: 90vw; */
    height: 27vh;
    /* padding-bottom: 56.25%; */
  }

  .popUp .popUpWrapper {
    padding: 15px;
  }
}
