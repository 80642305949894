.fullscreen {
  display: none;
  position: absolute;
  top: 30%;
  right: 50%;
  transform: translate(50%,50%);
  z-index: 3;
  cursor: pointer;
}
/* .fullscreen::after{
  background-color: black;
} */

.backdrop::after{
  display: none;
  background-color: rgba(0, 0, 0, 0.5);;
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  border-radius: 8px;
}


.modalBarrier{
  /* display: none; */
  background-color: rgba(0, 0, 0, 0.5);;
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.fullscreen-container{
  transition: all 200ms;
  position: absolute;
  display: inherit;
  width: 800px;
  height: 600px;
  border: 2px solid black;
  border-radius: 20px;
  overflow: hidden;
  z-index: 44;
  background-color: #fff;

}

.fullscreen-container > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}