.cardGallerySkeleton {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  border: 1px solid #e5e7eb;
  background-color: #e5e7eb;
  margin-bottom: 1rem;
}
.cardGallerySkeleton span:nth-of-type(1) {
  background-color: transparent;
  border-radius: 10px;
  height: 65px;
}

.cardGallerySkeleton span:nth-of-type(2) {
  background-color: transparent;
  border-radius: 10px;
  height: 15px;
}

.cardGallerySkeleton {
  animation: skeleton 0.5s linear infinite alternate;
}

@keyframes skeleton {
  to {
    opacity: 0.3;
  }
}
