//$color-background-body: #F9F9F9 !important;
$color-background-body: #F8FAFC !important;
$color-transparent: transparent !important;
$color-background-kanban: #EEF0F3 !important;
$color-white: #FFFFFF !important;
$color-dark: #273137 !important;
$color-dark-light: #69757C !important;
$color-grey: #DFE3E9 !important;
$color-grey-light: #EAECEE !important;
$color-primary: #004087 !important;
$color-secondary: #69757C !important;
$color-success: #1AE5B2 !important;
$color-warning: #F1C100 !important; // ! should be #F18200
$color-info: #0DCAF0 !important;
$color-danger: #BE123C !important;
$color-orange: #FFC977 !important; // fixed, before it matches $color-grey
$color-purple: #D1A3FF !important;
$color-sky-blue: #A6DFFF !important;
$color-green: #CFE770 !important;
$color-bg-front: #F7FBFF !important;

$box-shadow: 0px 0px 1px rgba(15, 23, 42, 0.03), 0px 4px 6px -1px rgba(15, 23, 42, 0.05), 0px 0px 5px 5px rgba(15, 23, 42, 0.03);;
$box-shadow-right: 0px 5px 5px 5px rgba(0, 0, 0, 0.2);
$box-shadow-none: none !important;

// TODO check name conventions against figma, some naming are wrong in sass variables
// ? this exported colors are used inside mui theme provider for mobili v2
:export {
  // greyLight: $color-grey-light;
  lightestGrey: $color-background-body; // bg-color
  lightGrey:$color-background-kanban;
  dark:$color-dark;
  darkGrey: $color-dark-light;
  mobiligrey:$color-secondary;
  mediumGrey:$color-grey;
  white:$color-white;
  mobilisuccess: $color-success;
  mobiliwarning: #F18200; //$color-warning;
  mobilidanger: $color-danger;
  orange: $color-orange;
  purple:$color-purple;
  skyBlue: $color-sky-blue;
  green:$color-green;

  primary: $color-primary;
}
