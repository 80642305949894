@charset "UTF-8";
@import './assets/scss/colors.scss';
@import './assets/scss/fonts.scss';

*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  width: 100%;
}

body {
  background-color: $color-background-body;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  overflow-x: hidden !important;
}

.back-to-top {
  margin: 0 !important;
  bottom: 30px !important;
  right: 30px !important;
  z-index: 1500 !important;
}

.border-l-4 {
  border-left-width: 4px !important;
  border-left-style: solid !important;
}

.border-radius-0 {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
  border-radius: 0 !important;
}

.border-radius-5 {
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
  border-radius: 5px !important;
}

.bg-transparent {
  background-color: $color-transparent;
}

.bg-white {
  background-color: $color-white;
}

.bg-dark {
  background-color: $color-dark;
  color: $color-white;
}

.bg-dark-outlined {
  background-color: $color-transparent;
  color: $color-dark;
  border-color: $color-dark;

  &:hover {
    background-color: $color-dark;
    color: $color-white;
  }
}

.bg-dark-light {
  background-color: $color-dark-light;
  color: $color-white;
}

.bg-dark-light-outlined {
  background-color: $color-transparent;
  color: $color-dark-light;
  border-color: $color-dark-light;

  &:hover,
  &:active,
  &.active {
    background-color: $color-dark-light;
    color: $color-white;
  }
}

.bg-grey {
  background-color: $color-grey;
  color: $color-dark;
}

.bg-grey-outlined {
  background-color: $color-transparent;
  color: $color-grey;
  border-color: $color-grey;

  &:hover {
    background-color: $color-grey;
    color: $color-dark;
  }
}

.bg-grey-light {
  background-color: $color-grey-light;
  color: $color-dark;
}

.bg-grey-light-outlined {
  background-color: $color-transparent;
  color: $color-grey-light;
  border-color: $color-grey-light;

  &:hover {
    background-color: $color-grey-light;
    color: $color-dark;
  }
}

.bg-primary {
  background-color: $color-primary;
  color: $color-white;

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), $color-primary;
  }
}

.bg-primary-outlined {
  background-color: $color-transparent;
  color: $color-primary;
  border-color: $color-primary;
}

.bg-primary-outlined {
  background-color: $color-transparent;
  color: $color-primary;
  border-color: $color-primary;

  &:hover {
    background-color: $color-primary;
    color: $color-white;
  }
}

.bg-secondary {
  background-color: $color-secondary;
  color: $color-dark;
}

.bg-secondary-outlined {
  background-color: $color-transparent;
  border-color: $color-secondary;
  color: $color-secondary;

  &:hover {
    background-color: $color-secondary;
    color: $color-dark;
  }
}

.bg-success {
  background-color: $color-success;
  color: $color-white;
}

.bg-success-outlined {
  background-color: $color-transparent;
  border-color: $color-success;
  color: $color-success;

  &:hover {
    background-color: $color-success;
    color: $color-white;
  }
}

.bg-warning {
  background-color: $color-warning;
  color: $color-white;
}

.bg-warning-outlined {
  background-color: $color-transparent;
  border-color: $color-warning;
  color: $color-warning;

  &:hover {
    background-color: $color-warning;
    color: $color-dark;
  }
}

.bg-info {
  background-color: $color-info;
  color: $color-white;
}

.bg-info-outlined {
  background-color: $color-transparent;
  border-color: $color-info;
  color: $color-info;

  &:hover {
    background-color: $color-info;
    color: $color-white;
  }
}

.bg-danger {
  background-color: $color-danger;
  color: $color-white;
}

.bg-danger-outlined {
  background-color: $color-transparent;
  border-color: $color-danger;
  color: $color-danger;

  &:hover {
    background-color: $color-danger;
    color: $color-white;
  }
}

.bg-orange {
  background-color: $color-orange;
  color: $color-white;
}

.bg-orange-outlined {
  background-color: $color-transparent;
  color: $color-orange;
  border-color: $color-orange;
}

.bg-purple {
  background-color: $color-purple;
  color: $color-white;
}

.bg-purple-outlined {
  background-color: $color-transparent;
  color: $color-purple;
  border-color: $color-purple;
}

.bg-sky-blue {
  background-color: $color-sky-blue;
  color: $color-white;
}

.bg-sky-blue-outlined {
  background-color: $color-transparent;
  color: $color-sky-blue;
  border-color: $color-sky-blue;
}

.bg-green {
  background-color: $color-green;
  color: $color-white;
}

.bg-green-outlined {
  background-color: $color-transparent;
  color: $color-green;
  border-color: $color-green;
}

.bg-kanban {
  background-color: $color-background-kanban;
}

.card {
  box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.2), 0 10px 14px 1px rgba(0, 0, 0, 0.14), 0 4px 18px 3px rgba(0, 0, 0, 0.12) !important;
  border-radius: 6px !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.card-kanban::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.card-kanban {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.card-filter {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 150px !important;

  &:hover {
    box-shadow: $box-shadow;
  }
}

.icon-state {
  visibility: hidden;
}

.fs-1 {
  font-size: 26px !important;
}

.fs-2 {
  font-size: 24px !important;
}

.fs-3 {
  font-size: 22px !important;
}

.fs-4 {
  font-size: 20px !important;
}

.fs-5 {
  font-size: 18px !important;
}

.fs-6 {
  font-size: 16px !important;
}

.fs-7 {
  font-size: 14px !important;
}

.fs-8 {
  font-size: 12px !important;
}

.fs-9 {
  font-size: 10px !important;
}

.h-20px {
  height: 20px !important;
}

.h-26px {
  height: 26px !important;
}

.h-30px {
  height: 30px !important;
}

.h-60px {
  height: 60px !important;
}

.h-70px {
  height: 70px !important;
}

.h-170px {
  height: 170px !important;
}

.has-error-content {
  background-color: $color-grey-light;
  border-color: $color-danger;
  border-left: 4px solid;
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
  color: $color-danger;
  font-size: 16px;
  min-height: 40px;
  padding: 0.5rem 0.5rem;
  position: relative;
  width: 100%;
}

.has-error-border {
  border: 1px solid $color-danger;
}

.left-0 {
  left: 0 !important;
}

.l-2 {
  left: .5rem !important;
  position: relative;
}

.link-dark {
  color: $color-dark;
  text-decoration: none !important;

  &:hover {
    text-decoration: none !important;
  }
}

.link-primary {
  color: $color-primary;
  text-decoration: underline !important;

  &:hover {
    text-decoration: none !important;
  }
}

.map-container{
  display: block;
  position: relative;
  margin: 0;
  height: 400px;
  padding: 0;
  width: 100%;
}

.max-h-120px {
  min-height: 30px !important;
  height: 30px !important;
  max-height: 120px !important;
}

.max-h-220 {
  max-height: 220px !important;
}

.max-h-450 {
  max-height: 450px !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.ml-3 {
  margin-left: 0.75rem !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.mr-3 {
  margin-right: 0.75rem !important;
}

.mb-3 {
  margin-bottom: 0.75rem !important;
}

.ml-4 {
  margin-left: 1rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mr-4 {
  margin-right: 1rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.ml-5 {
  margin-left: 1.25rem !important;
}

.mt-5 {
  margin-top: 1.25rem !important;
}

.mr-5 {
  margin-right: 1.25rem !important;
}

.mb-5 {
  margin-bottom: 1.25rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

.min-h-30px {
  min-height: 30px !important;
}

.min-h-40px {
  min-height: 40px !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pl-3 {
  padding-left: 0.75rem !important;
}

.pt-3 {
  padding-top: 0.75rem !important;
}

.pr-3 {
  padding-right: 0.75rem !important;
}

.pb-3 {
  padding-bottom: 0.75rem !important;
}

.pl-4 {
  padding-left: 1rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pr-4 {
  padding-right: 1rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pl-5 {
  padding-left: 1.25rem !important;
}

.pt-5 {
  padding-top: 1.25rem !important;
}

.pr-5 {
  padding-right: 1.25rem !important;
}

.pb-5 {
  padding-bottom: 1.25rem !important;
}

.right-0 {
  right: 0 !important;
}

.r-3 {
  right: 1rem !important;
}

.swal2-container {
  z-index: 1360 !important;
}

.status-pointer {
  cursor: pointer;
  border-radius: 50%;
  height: 15px;
  width: 15px;
}

.scrolling {
  -webkit-transition-property: top, bottom, width;
  -o-transition-property: top, bottom, width;
  transition-property: top, bottom, width;
  -webkit-transition-duration: .2s, .2s, .35s;
  -o-transition-duration: .2s, .2s, .35s;
  transition-duration: .2s, .2s, .35s;
  -webkit-transition-timing-function: linear, linear, ease;
  -o-transition-timing-function: linear, linear, ease;
  transition-timing-function: linear, linear, ease;
  -webkit-overflow-scrolling: touch;
  position: relative;
  overflow-x: hidden;
  height: calc(100vh - 10%);
  z-index: 4;
}

.table-dark {
  --bs-table-bg: $color-dark;
  --bs-table-color: $color-bg-front;
  background: $color-dark;
  color: $color-bg-front;
}

.table-secondary {
  --bs-table-bg: $color-grey;
  --bs-table-color: $color-dark;
  background: $color-grey;
  color: $color-dark;
}

tr {
  td > div.iconShow,
  td > div > div.iconShow {
    display: none !important;
  }

  &:hover {
    td > div.iconShow,
    td > div > div.iconShow {
      display: block !important;
    }
  }
}

.text-dark {
  color: $color-dark;
}

.text-dark-light {
  color: $color-dark-light;
}

.text-grey {
  color: $color-grey;
}

.text-grey-light {
  color: $color-grey-light;
}

.text-primary {
  color: $color-primary;
}

.text-secondary {
  color: $color-secondary;
}

.text-success {
  color: $color-success;
}

.text-warning {
  color: $color-warning;
}

.text-info {
  color: $color-info;
}

.text-danger {
  color: $color-danger;
}

.text-orange {
  color: $color-orange;
}

.text-purple {
  color: $color-purple;
}

.text-sky-blue {
  color: $color-sky-blue;
}

.text-green {
  color: $color-green;
}

.text-inherit {
  text-transform: inherit !important;
}

.text-underline {
  text-decoration: underline !important;

  &:hover {
    text-decoration: none !important;
  }
}

.top-3 {
  top: 3px !important;
  position: relative;
}

.top-7 {
  top: 7px !important;
  position: relative;
}

.top--8 {
  top: -8px !important;
  position: relative;
}

.w-20px {
  width: 20px !important;
}

.w-26px {
  width: 26px !important;
}

.w-30px {
  width: 30px !important;
}

.w-100px {
  width: 100px !important;
}

.w-150px {
  width: 150px !important;
}

.w-300px {
  width: 300px !important;
}

.w-35 {
  width: 35% !important;
}

.w-65 {
  width: 65% !important;
}

.min-w-100{
  min-width: 100px !important;
}

.line-clamp-1{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.dragover{
  border: 3px solid black;
  background-color: rgb(238, 230, 230);
  transform: scale(1.01);
}