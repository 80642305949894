@charset "UTF-8";
@import '../../../../assets/scss/colors';
@import '../../../../assets/scss/fonts';

.sidebar-header{
    //border-bottom: 1px solid $color-primary;
    vertical-align: middle;
    margin-bottom: -7px;

    .logo {
        height: auto;
        width: 130px;
    }
}
